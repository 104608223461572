import { assign, get, isArray } from 'lodash';
import ApiCancelRequestUtils from 'common/api/api-cancel-request';
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { datadogRum } from '@datadog/browser-rum';
import dayjs, { Dayjs } from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

import DateUtils from 'common/utils/date';
import {
  CONTENT_TYPE_JSON,
  STATUS_CODE_ACCEPTED,
  STATUS_CODE_EMPTY_CONTENT,
  STATUS_CODE_END_OK,
  STATUS_CODE_OK,
  STATUS_CODE_PARTIAL_CONTENT,
  VERB_HTTP_DELETE,
  VERB_HTTP_GET,
  VERB_HTTP_POST,
} from 'common/constants/http-request';

interface IRequestHeader {
  [key: string]: string;
}

interface IExtendAxiosRequestConfig extends AxiosRequestConfig {
  isCancellableRequest?: boolean;
  isRetryableRequest?: boolean;
  withPartialContentResponse?: boolean;
}

export default class ApiRequestUtils {
  public static request(
    url: string,
    token: string,
    locale: string,
    options: IExtendAxiosRequestConfig = {},
    customHeaders?: IRequestHeader,
  ): Promise<AxiosResponse> {
    return this.requestWithReponseHeader(
      url,
      token,
      locale,
      options,
      customHeaders,
    );
  }

  public static post(
    url: string,
    token: string,
    locale: string,
    data?: Record<string, unknown>,
    options?: IExtendAxiosRequestConfig,
    customHeaders?: IRequestHeader,
  ): Promise<AxiosResponse> {
    return this.requestBody(
      url,
      token,
      locale,
      VERB_HTTP_POST,
      data,
      options,
      customHeaders,
    );
  }

  public static delete(
    url: string,
    token: string,
    locale: string,
    data?: unknown,
    options?: IExtendAxiosRequestConfig,
    customHeaders?: IRequestHeader,
  ): Promise<AxiosResponse> {
    return this.requestBody(
      url,
      token,
      locale,
      VERB_HTTP_DELETE,
      data,
      options,
      customHeaders,
    );
  }

  public static readonly fetchHeaders = (token: string, locale: string) => {
    const headers: IRequestHeader = {
      Accept: CONTENT_TYPE_JSON,
      'Content-Type': CONTENT_TYPE_JSON,
      'Accept-Language': locale,
      Authorization: `Bearer ${token}`,
      'X-TimeZone': DateUtils.getGMTTimeZone(),
      'X-Correlation-Id': uuidv4(),
    };

    if (
      typeof import.meta.env.VITE_BFF_KEY === 'string' &&
      import.meta.env.VITE_BFF_KEY.trim() !== ''
    ) {
      headers['X-Api-Key'] = import.meta.env.VITE_BFF_KEY.trim();
    }

    return headers;
  };

  public static async requestWithReponseHeader(
    url: string,
    token: string,
    locale: string,
    extendedConfig: IExtendAxiosRequestConfig = {},
    customHeaders?: IRequestHeader,
  ): Promise<AxiosResponse> {
    const {
      isCancellableRequest = true,
      isRetryableRequest = true,
      withPartialContentResponse = false,
      ...options
    } = extendedConfig;

    const headers: IRequestHeader = assign(
      {},
      ApiRequestUtils.fetchHeaders(token, locale),
      customHeaders,
    );

    const requestToken = isCancellableRequest
      ? ApiCancelRequestUtils.addRequestToCancellableAndCancelOldRequest(
          url,
          options.method || VERB_HTTP_GET,
          withPartialContentResponse,
        )
      : {
          token: undefined,
        };

    const axiosConfig = assign(
      {
        method: options.method || VERB_HTTP_GET,
        responseType: 'json',
        responseEncoding: 'utf8',
        cancelToken: requestToken.token,
      },
      { isRetryableRequest, ...options },
      {
        url,
        headers,
      },
    );

    try {
      const response = await Axios.request(axiosConfig);

      if (isCancellableRequest && get(response, 'config')) {
        ApiCancelRequestUtils.removeRequestToCancellableOrSetStatus(
          response.config.url,
          response.config.method,
        );
      }

      if (
        (response && response.status < STATUS_CODE_OK) ||
        (response && response.status > STATUS_CODE_END_OK)
      ) {
        throw this.constructRequestPromiseError(
          Error,
          'Ajax method responded ko.',
          response,
        );
      }
      if (
        response &&
        get(response, 'config') &&
        response.status === STATUS_CODE_EMPTY_CONTENT
      ) {
        return { ...response, data: null };
      }
      if (
        (response &&
          response.config.method.toUpperCase() === VERB_HTTP_POST &&
          ((response && response.status === STATUS_CODE_ACCEPTED) ||
            (response && response.status === STATUS_CODE_PARTIAL_CONTENT))) ||
        (response && response.config.method.toUpperCase() === VERB_HTTP_DELETE)
      ) {
        return { ...response };
      }

      return response;
    } catch (error) {
      if (Axios.isCancel(error)) {
        return null;
      }

      datadogRum.addError(error, undefined);

      throw error;
    }
  }

  public static getBaseUriBff(version): string {
    if (
      typeof import.meta.env.VITE_BFF_URL !== 'string' ||
      import.meta.env.VITE_BFF_URL.trim() === ''
    ) {
      throw new Error('Url for BFF Sales Performances is missing !');
    }
    return `${import.meta.env.VITE_BFF_URL}/${version}/`.replace(/\/\/$/, '/');
  }

  public static getBffUrl({
    pathUrl,
    version,
    filters = null,
    params = null,
  }: {
    pathUrl: string;
    version: string;
    filters?: { [key: string]: any };
    params?: { [key: string]: any };
  }): string {
    const _params = this.convertToParamsUrl({
      ...filters,
      ...params,
    });

    return `${this.getBaseUriBff(version)}${pathUrl}${
      _params ? `?${_params}` : ''
    }`;
  }

  private static requestBody(
    url: string,
    token: string,
    locale: string,
    method: string,
    data: unknown,
    options?: IExtendAxiosRequestConfig,
    customHeaders?: IRequestHeader,
  ): Promise<AxiosResponse> {
    const optionsWithMethod = assign(
      { isCancellableRequest: true, isRetryableRequest: true },
      options,
      {
        method,
        data,
      },
    );

    return this.request(url, token, locale, optionsWithMethod, customHeaders);
  }

  private static constructRequestPromiseError(
    constructor,
    message: string,
    response: AxiosResponse,
  ): AxiosError {
    const error = new constructor(message) as AxiosError;
    error.config = response.config;
    error.response = response;

    return error;
  }

  private static encodeURIParam(data: any, convertArray = true): string {
    let paramValue = '';

    if (typeof data === 'number' || typeof data === 'boolean') {
      paramValue = data.toString();
    } else if (typeof data === 'object' && data instanceof dayjs) {
      paramValue = DateUtils.toDateString(data as Dayjs);
    } else if (isArray(data) && convertArray) {
      paramValue = data.map((d) => this.encodeURIParam(d, false)).join(',');
    } else if (data) {
      paramValue = encodeURIComponent(data.toString());
    }

    return paramValue;
  }

  private static convertToParamsUrl(params: { [key: string]: object }): string {
    return Object.entries(params)
      .filter(
        ([, value]) =>
          value !== undefined && (!isArray(value) || value.length > 0),
      )
      .map(([key, value]) => {
        return `${key}=${this.encodeURIParam(value)}`;
      })
      .join('&');
  }
}
