import { I18n } from 'react-redux-i18n';

import { ModelKpi } from 'common/models/enums/models/kpi-enum';

export default class ModelTableColumn {
  public readonly value: string;

  public readonly i18nKey: string;

  public readonly kpi: ModelKpi;

  public readonly weight: number;

  public readonly isDefault: boolean;

  public readonly enabled: boolean;

  public readonly i18nKeyOption: string;

  constructor(
    value: string,
    i18nKey: string,
    kpi: ModelKpi = null,
    weight = 0,
    isDefault = false,
    enabled: boolean = true,
    i18nKeyOption: string = null,
  ) {
    this.value = value;
    this.i18nKey = i18nKey;
    this.kpi = kpi;
    this.weight = weight;
    this.isDefault = isDefault;
    this.enabled = enabled;
    this.i18nKeyOption = i18nKeyOption || i18nKey;
  }

  public get isKpi(): boolean {
    return !!this.kpi;
  }

  public get label(): string {
    return I18n.t(`application.table.title.${this.i18nKey}`);
  }

  public get labelOption(): string {
    return I18n.t(`application.table.title.${this.i18nKeyOption}`);
  }
}
