import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthProvider } from 'oidc-react';
import { User } from 'oidc-client-ts';
import * as types from '../common/constants/action-types';

import AuthenticatedApp from './AuthenticatedApp';

const REDIRECT_URI_STORAGE_KEY = 'urlBeforeSignIn';
export default function Authentication() {
  const dispatch = useDispatch();
  const [error, setError] = useState<Error>();

  const resetUser = () => {
    dispatch({
      type: types.ResetUser,
      payload: null,
    });
  };
  const setUser = (usr: User) => {
    dispatch({
      type: types.SetUser,
      payload: {
        usr,
      },
    });
  };

  return (
    <AuthProvider
      authority={import.meta.env.VITE_FEDID_URL}
      clientId={import.meta.env.VITE_FEDID_CLIENT_ID}
      scope="openid profile"
      redirectUri={encodeURI(`${window.location.origin}/callback`)}
      onBeforeSignIn={() => {
        sessionStorage.setItem(
          REDIRECT_URI_STORAGE_KEY,
          window.location.href,
        );
      }}
      onSignIn={(user) => {
        setUser(user);
        const redirectUri = sessionStorage.getItem(REDIRECT_URI_STORAGE_KEY);
        if (redirectUri) {
          sessionStorage.removeItem(REDIRECT_URI_STORAGE_KEY);
          window.location.replace(redirectUri);
        }
      }}
      onSignOut={resetUser}
      onSignInError={setError}
    >
      <AuthenticatedApp error={error} />
    </AuthProvider>
  );
}
