import React from 'react';

import ErrorPage from 'core/router/errors/ErrorPage';

function Unauthorized() {
  return (
    <ErrorPage
      title="error.http.unauthorized.title"
      message="error.http.unauthorized.message"
    />
  );
}

export default Unauthorized;
