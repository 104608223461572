import { compact, head, isEmpty, map, orderBy } from 'lodash';
import { Dispatch } from 'redux';

import { ModelKpi } from 'common/models/enums/models/kpi-enum';
import ModelIncrease from 'common/models/enums/models/increase';
import ModelCurrencyFilter from 'common/models/enums/models/currency-filter';
import ModelSaleSupport from 'common/models/enums/models/sale-support';
import { OptionnalDropValue } from 'common/models/types/drop-value';
import { getValueOrDefaultOrDrop } from 'common/utils/global';
import LocalStorageUtils from 'common/utils/local-storage';
import { USER_FILTERS } from 'common/constants/cookies-keys';
import { TIME_WEEK } from 'common/constants/time';
import { ModelFiltersLocalStorage } from 'common/models/interfaces/filters';
import ModelDateTypeComparable from 'common/models/enums/models/date-type-comparable';
import ModelDateRange from 'common/models/enums/models/date-range';
import TypeDateComparableType from 'common/models/enums/types/date-type-comparable';
import TypeDateRange from 'common/models/enums/types/date-range';
import TypeSaleSupport from 'common/models/enums/types/sale-support';
import TypeKpiEnum from 'common/models/enums/types/kpi-enum';
import TypeIncrease from 'common/models/enums/types/increase';
import TypeCurrencyFilter from 'common/models/enums/types/currency-filter';
import { getFromAndToFromUrl } from 'common/utils/filter';
import { UpdateFilters } from 'common/constants/action-types';
import DateUtils from 'common/utils/date';
import ModelProductTypeFilter from 'common/models/enums/models/product-type-filter';
import TypeProductsTypeFilter from 'common/models/enums/types/products-type-filter';

class ModelFilters {
  public readonly kpis: ModelKpi[];

  public readonly increase: ModelIncrease;

  public readonly currency: ModelCurrencyFilter;

  public readonly salesSupport: ModelSaleSupport[];

  public readonly dateTypeComparable: ModelDateTypeComparable;

  public readonly dateRange: ModelDateRange;

  public readonly dateRangeComparable: ModelDateRange;

  public readonly productsType: ModelProductTypeFilter[];

  public readonly returnsIncluded: boolean;

  constructor({
    dateRange = TypeDateRange.YESTERDAY,
    dateRangeComparable = null,
    dateTypeComparable = TypeDateComparableType.DAY_OF_WEEK,
    kpis = [TypeKpiEnum.GMV],
    increase = TypeIncrease.GROWTH,
    currency = TypeCurrencyFilter.EURO,
    salesSupport = TypeSaleSupport.ALL,
    productsType = [TypeProductsTypeFilter.ONE_PRODUCT],
    returnsIncluded = true,
  }: {
    dateRange?: ModelDateRange;
    dateRangeComparable?: ModelDateRange;
    dateTypeComparable?: ModelDateTypeComparable;
    kpis?: ModelKpi[];
    increase?: ModelIncrease;
    currency?: ModelCurrencyFilter;
    salesSupport?: ModelSaleSupport[];
    productsType?: ModelProductTypeFilter[];
    returnsIncluded?: boolean;
  } = {}) {
    this.dateRange = dateRange;
    this.dateRangeComparable = dateRangeComparable;
    this.dateTypeComparable = dateTypeComparable;
    this.kpis = kpis;
    this.increase = increase;
    this.currency = currency;
    this.salesSupport = salesSupport;
    this.productsType = productsType;
    this.returnsIncluded = returnsIncluded;
  }

  public clone({
    dateRange = null,
    dateRangeComparable = null,
    dateTypeComparable = null,
    kpis = null,
    increase = null,
    currency = null,
    salesSupport = null,
    productsType = null,
    returnsIncluded = null,
  }: {
    dateRange?: OptionnalDropValue<ModelDateRange>;
    dateRangeComparable?: OptionnalDropValue<ModelDateRange>;
    dateTypeComparable?: OptionnalDropValue<ModelDateTypeComparable>;
    kpis?: OptionnalDropValue<ModelKpi[]>;
    increase?: OptionnalDropValue<ModelIncrease>;
    currency?: OptionnalDropValue<ModelCurrencyFilter>;
    salesSupport?: OptionnalDropValue<ModelSaleSupport[]>;
    productsType?: OptionnalDropValue<ModelProductTypeFilter[]>;
    returnsIncluded?: OptionnalDropValue<boolean>;
  } = {}): ModelFilters {
    const computeDateRange = getValueOrDefaultOrDrop(dateRange, this.dateRange);
    const computeDateRangeComparable = getValueOrDefaultOrDrop(
      dateRangeComparable,
      this.dateRangeComparable,
    );
    const computeDateTypeComparableKpi = getValueOrDefaultOrDrop(
      dateTypeComparable,
      this.dateTypeComparable,
    );
    const computeKpi = getValueOrDefaultOrDrop(kpis, this.kpis);
    const computeIncrease = getValueOrDefaultOrDrop(increase, this.increase);
    const computeCurrency = getValueOrDefaultOrDrop(currency, this.currency);
    const computeSalesSupport = getValueOrDefaultOrDrop(
      salesSupport,
      this.salesSupport,
    );
    const computeProductsType = getValueOrDefaultOrDrop(
      productsType,
      this.productsType,
    );
    const computeReturnsIncluded = getValueOrDefaultOrDrop(
      returnsIncluded,
      this.returnsIncluded,
    );

    return new ModelFilters({
      dateRange: computeDateRange,
      dateRangeComparable: computeDateRangeComparable,
      dateTypeComparable: computeDateTypeComparableKpi,
      kpis: computeKpi,
      increase: computeIncrease,
      currency: computeCurrency,
      salesSupport: computeSalesSupport,
      productsType: computeProductsType,
      returnsIncluded: computeReturnsIncluded,
    });
  }

  public static initialize(
    urlParsed: URLSearchParams,
    localStorage: ModelFiltersLocalStorage,
  ): ModelFilters {
    const initializedDateRange = this.initializeDateRange(
      urlParsed,
      localStorage,
    );
    const initializedDateRangeComparable = this.initializeDateRangeComparable(
      urlParsed,
      initializedDateRange,
    );

    const initializedDateTypeComparable = this.initializeDateTypeComparable(
      urlParsed,
      localStorage,
    );
    const initializedKpis = this.initializeKpis(urlParsed, localStorage);
    const initializedCurrency = this.initializeCurrency(
      urlParsed,
      localStorage,
    );
    const initializedIncrease = this.initializeIncrease(
      urlParsed,
      localStorage,
    );
    const initializedSalesSupport = this.initializeSalesSupport(
      urlParsed,
      localStorage,
    );

    const initializedProductsType = this.initializeProductsType(
      urlParsed,
      localStorage,
    );

    const initializedReturnsIncluded = this.initializeReturnsIncluded(
      urlParsed,
      localStorage,
    );

    return new ModelFilters({
      dateRange: initializedDateRange,
      dateRangeComparable: initializedDateRangeComparable,
      dateTypeComparable:
        initializedDateTypeComparable || TypeDateComparableType.DAY_OF_WEEK,
      kpis: initializedKpis || [TypeKpiEnum.GMV],
      increase: initializedIncrease || TypeIncrease.GROWTH,
      currency: initializedCurrency || TypeCurrencyFilter.EURO,
      salesSupport: initializedSalesSupport || TypeSaleSupport.ALL,
      productsType: initializedProductsType || [
        TypeProductsTypeFilter.ONE_PRODUCT,
      ],
      returnsIncluded: initializedReturnsIncluded || true,
    });
  }

  private static initializeDateRange(
    urlParsed: URLSearchParams,
    localStorage: ModelFiltersLocalStorage,
  ): ModelDateRange {
    const urlFromValue = urlParsed.get('from');
    const urlToValue = urlParsed.get('to');

    if (isEmpty(urlFromValue) && !localStorage) {
      return TypeDateRange.YESTERDAY;
    }

    if (!urlFromValue && localStorage?.dateRange) {
      return TypeDateRange.byLocalStorageValue(localStorage.dateRange);
    }

    const { from, to } = getFromAndToFromUrl(urlFromValue, urlToValue);

    const foundDateRange = TypeDateRange.byFromAndTo(from, to);

    return foundDateRange || TypeDateRange.getCustomDate(from, to);
  }

  private static initializeDateRangeComparable(
    urlParsed: URLSearchParams,
    initializedDateRange: ModelDateRange,
  ): ModelDateRange {
    const urlFromValue = urlParsed.get('fromComparable');
    const urlToValue = urlParsed.get('toComparable');

    if (isEmpty(urlFromValue)) {
      return DateUtils.getPeriodByType(
        initializedDateRange,
        TypeDateComparableType.DAY_OF_WEEK,
      );
    }

    const { from, to } = getFromAndToFromUrl(urlFromValue, urlToValue);

    const foundDateRangeComparable = TypeDateRange.byFromAndTo(from, to);

    return foundDateRangeComparable || TypeDateRange.getCustomDate(from, to);
  }

  private static initializeDateTypeComparable(
    urlParsed: URLSearchParams,
    localStorage: ModelFiltersLocalStorage,
  ): ModelDateTypeComparable {
    const urlValue = urlParsed.get('dateTypeComparable');

    if (isEmpty(urlValue) && !localStorage) {
      return null;
    }

    if (isEmpty(urlValue) && localStorage?.dateTypeComparable) {
      return TypeDateComparableType.byValue(localStorage.dateTypeComparable);
    }

    return !isEmpty(urlValue) && TypeDateComparableType.byValue(urlValue);
  }

  private static initializeKpis(
    urlParsed: URLSearchParams,
    localStorage: ModelFiltersLocalStorage,
  ): ModelKpi[] {
    const urlValue = urlParsed.get('kpis');

    if (isEmpty(urlValue) && !localStorage) {
      return null;
    }

    const kpiValues = urlValue?.split(',') || localStorage?.kpis;

    const kpis = compact(map(kpiValues, (kpi) => TypeKpiEnum.byValue(kpi)));

    return !isEmpty(kpis) && kpis;
  }

  private static initializeIncrease(
    urlParsed: URLSearchParams,
    localStorage: ModelFiltersLocalStorage,
  ): ModelIncrease {
    const urlValue = urlParsed.get('comparability');

    if (isEmpty(urlValue) && !localStorage) {
      return null;
    }

    if (isEmpty(urlValue) && localStorage?.increase) {
      return TypeIncrease.byValue(localStorage.increase);
    }

    return !isEmpty(urlValue) && TypeIncrease.byValue(urlValue);
  }

  private static initializeCurrency(
    urlParsed: URLSearchParams,
    localStorage: ModelFiltersLocalStorage,
  ): ModelCurrencyFilter {
    const urlValue = urlParsed.get('currency');

    if (isEmpty(urlValue) && localStorage?.currency) {
      return TypeCurrencyFilter.byValue(localStorage.currency);
    }

    return !isEmpty(urlValue) && TypeCurrencyFilter.byValue(urlValue);
  }

  private static initializeSalesSupport(
    urlParsed: URLSearchParams,
    localStorage: ModelFiltersLocalStorage,
  ): ModelSaleSupport[] {
    const urlValue = urlParsed.get('salesSupports');

    if (isEmpty(urlValue) && !localStorage) {
      return null;
    }

    const saleSupportValues =
      urlValue?.split(',') || localStorage?.salesSupport;

    const saleSupports = compact(
      map(saleSupportValues, (saleSupport) =>
        TypeSaleSupport.byId(saleSupport),
      ),
    );

    return !isEmpty(saleSupports) && saleSupports;
  }

  private static initializeProductsType(
    urlParsed: URLSearchParams,
    localStorage: ModelFiltersLocalStorage,
  ): ModelProductTypeFilter[] {
    const urlValue = urlParsed.get('seller');

    if (isEmpty(urlValue) && !localStorage) {
      return null;
    }

    const productsTypeValues =
      urlValue?.split(',') || localStorage?.productsType;

    const productsType = compact(
      map(productsTypeValues, (productType) =>
        TypeProductsTypeFilter.byValue(productType),
      ),
    );

    return !isEmpty(productsType) && productsType;
  }

  private static initializeReturnsIncluded(
    urlParsed: URLSearchParams,
    localStorage: ModelFiltersLocalStorage,
  ): boolean {
    const urlValue = urlParsed.get('returnsIncluded');

    if (isEmpty(urlValue) && !localStorage) {
      return null;
    }

    return urlValue === 'sale,return,cancel' || localStorage?.returnsIncluded;
  }

  public get filtersWithOneKpi() {
    return this.clone({
      dateRange: null,
      dateRangeComparable: null,
      dateTypeComparable: null,
      kpis: isEmpty(this.firstKpiSelected) ? [] : [this.firstKpiSelected],
    });
  }

  public get firstKpiSelected() {
    const selectedFilersOrderedByWeight = orderBy(this.kpis, 'weight', 'asc');
    return head(selectedFilersOrderedByWeight);
  }

  public static update(filters: ModelFilters, dispatch: Dispatch): void {
    dispatch({
      type: UpdateFilters,
      payload: filters,
    });

    this.persist(filters);
  }

  private static persist(filters: ModelFilters): void {
    const modelFiltersToJson: ModelFiltersLocalStorage = {
      dateRange: filters?.dateRange?.localStorageValue,
      dateRangeComparable: null,
      dateTypeComparable: filters?.dateTypeComparable?.value,
      kpis: filters.kpis && map(filters.kpis, (filter) => filter.value),
      salesSupport:
        filters.salesSupport &&
        map(filters.salesSupport, (filter) => filter.id),
      increase: filters?.increase.value,
      currency: filters?.currency.value,
      productsType:
        filters.productsType &&
        map(filters.productsType, (productType) => productType.value),
      returnsIncluded: filters.returnsIncluded,
    };

    return LocalStorageUtils.setItem(
      USER_FILTERS,
      JSON.stringify(modelFiltersToJson),
      TIME_WEEK,
    );
  }

  public queryParams(): string[] {
    const params = [];

    if (this.dateRange) {
      params.push(`from=${this.dateRange.from.format('YYYYMMDD')}`);
      params.push(`to=${this.dateRange.to.format('YYYYMMDD')}`);
    }
    if (this.dateRangeComparable) {
      params.push(
        `fromComparable=${this.dateRangeComparable.from.format('YYYYMMDD')}`,
      );
      params.push(
        `toComparable=${this.dateRangeComparable.to.format('YYYYMMDD')}`,
      );
    }
    if (this.dateTypeComparable) {
      params.push(`dateTypeComparable=${this.dateTypeComparable.value}`);
    }
    if (this.kpis) {
      const kpisValues = map(this.kpis, (kpi) => kpi.value);
      params.push(`kpis=${kpisValues.join(',')}`);
    }
    if (this.increase) {
      params.push(`comparability=${this.increase.value}`);
    }
    if (this.currency) {
      params.push(`currency=${this.currency.value}`);
    }

    if (this.salesSupport) {
      const salesSupportsValues = map(
        this.salesSupport,
        (saleSupport) => saleSupport.id,
      );
      params.push(`salesSupports=${salesSupportsValues.join(',')}`);
    }

    if (this.returnsIncluded !== undefined || this.returnsIncluded !== null) {
      params.push(`returnIncluded=${this.returnsIncluded}`);
    }

    return params;
  }
}

export default ModelFilters;
