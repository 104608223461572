import {
  IModelLocalStorageTable,
  IModelTable,
} from 'common/models/interfaces/table';
import { compact, head, isEmpty, isEqual, map, sortBy } from 'lodash';
import TypeTableColumnEnum from 'common/models/enums/types/TypeTableColumnEnum';
import TypeTableViewOptionEnum from 'common/models/enums/types/TypeTableViewOptionEnum';
import ModelTableColumn from 'common/models/enums/models/ModelTableColumn';
import INITIAL_STATE_TABLE from 'common/constants/initial-state-table';
import ModelTableViewOption from 'common/models/enums/models/ModelTableViewOption';
import TypeTableSortMethodEnum from 'common/models/enums/types/TypeTableSortMethodEnum';
import ModelTableSortMethod from 'common/models/enums/models/ModelTableSortMethod';
import ModelFilters from 'common/models/ModelFilters';
import { ModelKpi } from '../models/enums/models/kpi-enum';

export const convertModelTableToLocalStorage = (
  newStateFilter: IModelTable,
): Partial<IModelLocalStorageTable> => {
  const columnsValue =
    newStateFilter.columns &&
    map(compact(newStateFilter.columns), (column) => column.value);
  const viewOptionsValue = map(
    compact(newStateFilter.viewOptions),
    (viewOption) => viewOption.value,
  );
  const sortByValue = newStateFilter.sortBy && newStateFilter.sortBy.value;
  const showBorderValue = newStateFilter.showBorder;
  const paginateValue = newStateFilter.paginate;
  const fixHeaderValue = newStateFilter.fixHeader;

  return {
    columns: columnsValue,
    viewOptions: viewOptionsValue,
    sortBy: sortByValue,
    showBorder: showBorderValue,
    paginate: paginateValue,
    fixHeader: fixHeaderValue,
  };
};

export const findFirstViewOptionsSelected = (
  viewOptions: ModelTableViewOption[],
): ModelTableViewOption => {
  const viewOptionsFilteredByWeight = sortBy(viewOptions, 'weight');
  return head(viewOptionsFilteredByWeight);
};

export const generateSortKey = (
  firstKpiColumnSelected: ModelTableColumn | ModelKpi,
  firstViewOptionSelected: ModelTableViewOption,
  sort: ModelTableSortMethod,
  dataIndexPrefix: string = null,
): string => {
  let key = '';

  if (dataIndexPrefix) {
    key += `${dataIndexPrefix}.`;
  }

  if (firstKpiColumnSelected) {
    key += `${firstKpiColumnSelected.value}.`;
  }

  if (firstViewOptionSelected) {
    key += `${firstViewOptionSelected.value}`;
  }

  if (isEqual(sort, TypeTableSortMethodEnum.PROGRESSION)) {
    key += `Rate`;
  }

  return key;
};

export const getSortKey = (
  filters: ModelFilters,
  table: IModelTable,
  dataIndexPrefix: string = null,
): string => {
  const firstViewOptionSelected = findFirstViewOptionsSelected(
    table.viewOptions,
  );

  return generateSortKey(
    filters.firstKpiSelected,
    firstViewOptionSelected,
    table.sortBy,
    dataIndexPrefix,
  );
};

export const loadColumns = (
  urlParsed: URLSearchParams,
  localStorage: IModelLocalStorageTable,
): ModelTableColumn[] => {
  const urlValue = urlParsed.get('columns');

  if (isEmpty(urlValue) && !localStorage) {
    return INITIAL_STATE_TABLE.columns;
  }

  const urlColumnsParamSplited = urlValue?.split(',') || localStorage?.columns;

  if (isEmpty(urlColumnsParamSplited)) {
    return INITIAL_STATE_TABLE.columns;
  }

  return compact(
    urlColumnsParamSplited.map((column) => TypeTableColumnEnum.byValue(column)),
  );
};

export const loadViews = (
  urlParsed: URLSearchParams,
  localStorage: IModelLocalStorageTable,
): ModelTableViewOption[] => {
  const urlValue = urlParsed.get('views');

  if (isEmpty(urlValue) && !localStorage) {
    return INITIAL_STATE_TABLE.viewOptions;
  }

  const urlViewsParamSplited =
    urlValue?.split(',') || localStorage?.viewOptions;

  if (isEmpty(urlViewsParamSplited)) {
    return INITIAL_STATE_TABLE.viewOptions;
  }

  return compact(
    urlViewsParamSplited.map((view) => TypeTableViewOptionEnum.byValue(view)),
  );
};

export const loadSortBy = (
  urlParsed: URLSearchParams,
  localStorage: IModelLocalStorageTable,
): ModelTableSortMethod => {
  const urlValue = urlParsed.get('sortBy') || localStorage?.sortBy;

  if (isEmpty(urlValue) && !localStorage) {
    return INITIAL_STATE_TABLE.sortBy;
  }

  const foundSortBy = TypeTableSortMethodEnum.byValue(urlValue);

  return foundSortBy || INITIAL_STATE_TABLE.sortBy;
};
