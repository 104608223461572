import React from 'react';
import LogoLoader from '../../common/components/loader/logo-loader';
import { useI18n } from '../../common/utils/hookI18n';
import NavigatorUtils from '../../common/utils/navigator';

const Maintenance = () => {
  const I18n = useI18n();

  const title =
    NavigatorUtils.getLanguage() === 'fr'
      ? import.meta.env.VITE_MAINTENANCE_TITLE_FR
      : import.meta.env.VITE_MAINTENANCE_TITLE_EN;

  const description =
    NavigatorUtils.getLanguage() === 'fr'
      ? import.meta.env.VITE_MAINTENANCE_DESCRIPTION_FR
      : import.meta.env.VITE_MAINTENANCE_DESCRIPTION_EN;

  return (
    <div className={'maintenance-container'}>
      <div className={'maintenance'}>
        <p className="vtmn-typo_display-2">{I18n.t('application.title')}</p>
        <LogoLoader className={'center-loader'} />
        <p className="vtmn-typo_title-4">
          {title || I18n.t('error.maintenance.title')}
        </p>
        <p>{description}</p>
      </div>
    </div>
  );
};
Maintenance.propTypes = {};
Maintenance.defaultProps = {};
export default Maintenance;
