import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AuthProvider } from 'oidc-react';
import { User } from 'oidc-client-ts';
import * as types from '../common/constants/action-types';
import AuthenticatedApp from './AuthenticatedApp';

export default function Authentication() {
  const dispatch = useDispatch();
  const [error, setError] = useState<Error>();

  const resetUser = () => {
    dispatch({
      type: types.ResetUser,
      payload: null,
    });
  };
  const setUser = (usr: User) => {
    dispatch({
      type: types.SetUser,
      payload: {
        usr,
      },
    });
  };

  return (
    <AuthProvider
      authority={import.meta.env.VITE_FEDID_URL}
      clientId={import.meta.env.VITE_FEDID_CLIENT_ID}
      scope="openid profile"
      redirectUri={encodeURI(`${window.location.origin}/callback`)}
      onSignIn={setUser}
      onSignOut={resetUser}
      onSignInError={setError}
    >
      <AuthenticatedApp error={error} />
    </AuthProvider>
  );
}
