import { I18n } from 'react-redux-i18n';
import ModelUnitEnum from 'common/models/enums/models/unit-enum';
import Optional from 'common/models/types/optional';

interface ModelKpi {
  value: string;
  urlParam: string;
  keyTranslate: string;
  groupKeyTranslate: Optional<string>;
  weight: number;
  unitVolume: ModelUnitEnum;
  unitProgression: ModelUnitEnum;
  showColumnsShareAmount: boolean;
  precision: number;
  ratePrecision: number;
}

const createModelKpi = ({
  value,
  urlParam = value,
  keyTranslate,
  groupKeyTranslate,
  weight,
  unitVolume,
  unitProgression = unitVolume,
  showColumnsShareAmount = true,
  precision = 0,
  ratePrecision = 2,
}: {
  value: string;
  urlParam?: string;
  keyTranslate: string;
  groupKeyTranslate: Optional<string>;
  weight: number;
  unitVolume: ModelUnitEnum;
  unitProgression?: ModelUnitEnum;
  showColumnsShareAmount?: boolean;
  precision?: number;
  ratePrecision?: number;
}): ModelKpi => ({
  value,
  urlParam,
  keyTranslate,
  groupKeyTranslate,
  weight,
  unitVolume,
  unitProgression,
  showColumnsShareAmount,
  precision,
  ratePrecision,
});

const getLabel = (modelKpi: ModelKpi): string | null =>
  modelKpi.groupKeyTranslate
    ? I18n.t(
        `application.filter.${modelKpi.groupKeyTranslate}.${modelKpi.keyTranslate}`,
      )
    : null;

const getReturnIncludedLabel = (modelKpi: ModelKpi): string | null =>
  modelKpi.groupKeyTranslate
    ? I18n.t(
        `application.filter.${modelKpi.groupKeyTranslate}.ordered.${modelKpi.keyTranslate}`,
      )
    : null;

const getLabelWithOf = (modelKpi: ModelKpi): string | null =>
  modelKpi.groupKeyTranslate
    ? I18n.t(
        `application.filter.${modelKpi.groupKeyTranslate}.with-of.${modelKpi.keyTranslate}`,
      )
    : null;

const getReturnIncludedLabelWithOf = (modelKpi: ModelKpi): string | null =>
  modelKpi.groupKeyTranslate
    ? I18n.t(
        `application.filter.${modelKpi.groupKeyTranslate}.with-of.ordered.${modelKpi.keyTranslate}`,
      )
    : null;

const getTitleTable = (modelKpi: ModelKpi): string =>
  I18n.t(`application.table.title.${modelKpi.keyTranslate}`);

const getReturnIncludedTitleTable = (modelKpi: ModelKpi): string =>
  I18n.t(`application.table.title.ordered.${modelKpi.keyTranslate}`);

const getTitleSalesSupportGraphOverview = (
  modelKpi: ModelKpi,
  returnIncluded?: boolean,
): string =>
  I18n.t(
    `application.overview.chart.sales-support${
      !returnIncluded ? '.ordered' : ''
    }.${modelKpi.keyTranslate}`,
  );

const getTitleHistogramGraphOverview = (
  modelKpi: ModelKpi,
  returnIncluded?: boolean,
): string =>
  I18n.t('application.overview.chart.histogram', {
    salesSupportLabel: !returnIncluded
      ? getReturnIncludedLabelWithOf(modelKpi)
      : getLabelWithOf(modelKpi),
  });

const getTitleHistogramGraphOverviewAvgOrder = (
  modelKpi: ModelKpi,
  returnIncluded?: boolean,
): string =>
  I18n.t(
    `application.overview.chart${!returnIncluded ? '.ordered' : ''}.histogram-${
      modelKpi.keyTranslate
    }`,
  );

const getTitleHistogramGraphOverviewTicket = (
  modelKpi: ModelKpi,
  returnIncluded?: boolean,
): string =>
  I18n.t(
    `application.overview.chart${!returnIncluded ? '.ordered' : ''}.histogram-${
      modelKpi.keyTranslate
    }`,
  );

export {
  createModelKpi,
  getLabel,
  getLabelWithOf,
  getReturnIncludedLabel,
  getReturnIncludedLabelWithOf,
  getTitleTable,
  getReturnIncludedTitleTable,
  getTitleSalesSupportGraphOverview,
  getTitleHistogramGraphOverview,
  getTitleHistogramGraphOverviewAvgOrder,
  getTitleHistogramGraphOverviewTicket,
};
export type { ModelKpi };
